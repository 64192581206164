import React from 'react';
import Image from 'reusecore/src/elements/Image';
import { Link } from 'gatsby';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import google_tasks_1 from '../../../../../../../common/src/assets/image/google_tasks_1.png';
import google_allow_access from '../../../../../../../common/src/assets/image/2019/google_allow_access.png';
import google_tasks_2 from '../../../../../../../common/src/assets/image/google_tasks_2.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal can{' '}
        <Link to="/Documentation/Create-Calendar/Choose-a-Template">
          create printable calendars
        </Link>{' '}
        containing tasks imported from Google Calendar. You can connect
        PrintableCal to any number of Google accounts. To add Google tasks,
        follow these steps:
      </p>
      <ol className="numbered">
        <li>
          Click the <strong>Add Calendar</strong> button and select{' '}
          <strong>Online -&gt; Google Tasks</strong>.
        </li>
        <li>
          A window will appear where you can select a previously connected
          account, or add a connection to a new account. To add a connection,
          enter your account name and click the Next button. <br />
          <Image alt="" src={google_tasks_1} style={{ width: 737 }} />
        </li>
        <li>
          A web page will appear where you can confirm your account selection.
          After confirming, click the "Allow" button.
          <br />
          <Image alt="" src={google_allow_access} style={{ width: 479 }} />
        </li>
        <li>
          After allowing access, the list of task lists in your account will
          appear. Use the check boxes to select which task lists should be added
          to PrintableCal.
          <br />
          <Image alt="" src={google_tasks_2} style={{ width: 737 }} />
        </li>
        <li>
          Click the <strong>OK</strong> button when you're done selected the
          desired task lists.
        </li>
      </ol>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Calendar-Data-Sources/Google-Tasks"
      commentsId="commentsplus_post_138_1624"
      title="Print Google Tasks"
      description="PrintableCal can create printable calendars containing tasks imported from Google Calendar."
      keywords="Google Tasks, print Google Tasks, import Google tasks, Gantt Chart, printable tasks, printable calendar, task calendar template, 2020 tasks"
      content={content}
    />
  );
};

export default Documentation;
